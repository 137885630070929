<template>
  <div class="topic">
    <div class="content">
      <Breadcrumb></Breadcrumb>
      <div class="box">
        <div class="content-left">
          <el-card>
            <search-filter
              ref="searchFilter"
              :searchName="'搜索题目'"
              :keywordOptions="{
                placeholder: '请输入题目名称或题目编号',
                prop: 'title',
                autoName: 'topic',
                autocomplete: true,
              }"
              :searchOptions="[
                {
                  name: 'el-form-item',
                  options: {
                    label: '难度',
                    prop: 'diff_id',
                    type: 'radio',
                    data: { name: 'question', keys: 'diff_id' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '来源',
                    prop: 'from_id',
                    type: 'radio',
                    data: { name: 'question', keys: 'from_id' },
                    defaultValue: '全部',
                  },
                },
                {
                  name: 'el-form-item',
                  options: {
                    label: '类型',
                    prop: 'type',
                    type: 'radio',
                    data: { name: 'question', keys: 'type2' },
                    defaultValue: '全部',
                  },
                },
              ]"
              :otherOptions="[
                // {
                //   name: 'el-form-item',
                //   options: {
                //     label: '年份',
                //     prop: 'time_str',
                //     type: 'year',
                //     placeholder: '请选择时间',
                //   },
                // },
                {
                  name: 'el-form-item',
                  options: {
                    label: '地区',
                    prop: 'province_id',
                    type: 'select',
                    placeholder: '请选择地区',
                    data: {
                      name: 'question',
                      keys: 'province_id',
                      isAll: false,
                    },
                  },
                },
              ]"
              v-model="where"
              :total="total"
              @search="currentChange(1)"
            ></search-filter>
            <div class="operate">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-document-checked"
                style="height: 30px"
                v-if="
                  (userInfo.type === 1 || userInfo.type === 2) &&
                  where.match_type != 2
                "
                @click="currentChange(((where.match_type = 2), 1))"
              >
                我做过的题目
              </el-button>
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                icon="el-icon-document-copy"
                v-if="
                  (userInfo.type === 1 || userInfo.type === 2) &&
                  where.match_type == 2
                "
                @click="currentChange(((where.match_type = null), 1))"
              >
                全部题目
              </el-button>
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                icon="el-icon-document"
                v-if="userInfo.type === 2"
                @click="showMytopicList"
              >
                我管理的题目
              </el-button>
              <el-button
                type="primary"
                size="mini"
                style="height: 30px"
                icon="el-icon-circle-plus-outline"
                v-if="$isPowers('questionCreate') || userInfo.type == 2"
                @click="goNewTopicList"
              >
                新建题目
              </el-button>
            </div>
          </el-card>
          <div class="operate">
            <!-- <div
              :style="{ padding: '4px 20px' }"
              style="margin-right: 2px;font-size: 12px;"

              :class="[
                'button-primary',
              ]"
              v-if="userInfo.type === 1 || userInfo.type === 2"
              @click="currentChange(((where.match_type = 2), 1))"
            >
              我做过的题目
            </div>

            <div
              :style="{ padding: '4px 20px' }"
              style="margin-right: 5px;font-size: 12px;"
              
              :class="[
                'button-primary',
              ]"
              v-if="(userInfo.type === 1 || userInfo.type === 2) && where.match_type == 2"
              @click="currentChange(((where.match_type = null), 1))"
            >
              全部题目
            </div>

            <div
              :style="{ marginLeft: '5px', padding: '4px 20px' }"
              :class="[
                'new_problemList',
                'button-primary',
                where.match_type + '' === '1' ? 'myEstablish' : '',
              ]"
              v-if="userInfo.type === 2"
              @click="currentChange(((where.match_type = 1), 1))"
            >
              我创建的题目
            </div> -->

            <!-- <div
              :style="{ marginLeft: '5px', padding: '4px 20px' }"
              :class="['new_problemList', 'button-primary']"
              v-if="$isPowers('questionCreate')"
              @click="$refs.questionSave.visibled(true)"
            >
              新建题目
            </div> -->
            <!-- <div
              :style="{ marginLeft: '5px', padding: '4px 20px' }"
              style="font-size: 12px;"
              
              :class="['new_problemList', 'button-primary']"
              v-if="$isPowers('questionCreate') || userInfo.type==2"
              @click="goNewTopicList"
            >
              新建题目
            </div> -->

            <!-- <div
              :style="{ marginLeft: '5px', padding: '4px 20px' }"
              :class="['new_problemList', 'button-primary']"
              v-if="$isPowers('questionImport')"
              @click="importData"
            >
              导入
            </div> -->
          </div>
          <div class="table-content"  v-loading="loading">
            <el-table
              :data="questionData"
              tooltip-effect="dark"
              @row-click="rowDblclick"

              style="width: 100%"
              height="800"
              :row-style="{ height: '0' }"
              :cell-style="{ padding: '6px' }"
              :header-cell-style="{
                height: '50',
                padding: '4px',
                background: '#F9F9F9',
              }"
            >
              <el-table-column
                label="编号"
                prop="id"
                align="center"
              >
              </el-table-column>
              <el-table-column label="题目" align="center">
                <template slot-scope="scope">
                  <span v-html="scope.row.title"></span>
                </template>
              </el-table-column>
              <el-table-column label="难度" align="center">
                <template slot-scope="scope">
                  <div
                    class="btn"
                    :style="{ background: scope.row.diff_color }"
                    v-if="scope.row.diff_title"
                  >
                    {{ scope.row.diff_title }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <el-table-column label="来源" align="center">
                <template slot-scope="scope">
                  <div
                    class="btn"
                    :style="{ background: scope.row.from_color }"
                    v-if="scope.row.from_title"
                  >
                    {{ scope.row.from_title }}
                  </div>
                  <div v-else>--</div>
                </template>
              </el-table-column>
              <el-table-column label="AC次数" align="center">
                <template slot-scope="scope">{{ scope.row.ac_num }}</template>
              </el-table-column>
              <el-table-column label="提交次数" align="center">
                <template slot-scope="scope">{{
                  scope.row.respondent_num
                }}</template>
              </el-table-column>
              <el-table-column label="AC率" align="center">
                <template slot-scope="scope">{{ scope.row.ac_rate+'%' }}</template>
              </el-table-column>
              <el-table-column
                label="操作"
                align="center"
                width="100"
                v-if="userInfo.type === 2"
              >
                <template slot-scope="scope">
                  <div class="operation">
                    <i
                      class="el-icon-delete"
                      @click.stop="del(scope.row)"
                      v-if="userInfo.id + '' === scope.row.user_id + ''"
                    ></i>
                    <span v-else>--</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>

            <!-- <ProblemListTable
              :operation="operationTable"
              :rowDblclickPath="'QuestionInfo'"
            >
              <el-table-column
                label="题目类型"
                align="center"
                slot="topicType"
                width="150"
              >
                <template slot-scope="scope">
                  <span>{{ scope.row.type_str }}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="操作"
                align="center"
                width="100"
                v-if="userInfo.type === 2"
              >
                <template slot-scope="scope">
                  <div class="operation">
                    <i
                      class="el-icon-delete"
                      @click.stop="del(scope.row)"
                      v-if="userInfo.id + '' === scope.row.user_id + ''"
                    ></i>
                    <span v-else>--</span>
                  </div>
                </template>
              </el-table-column>
            </ProblemListTable> -->
            <div class="pagination">
              <el-pagination
                background
                layout="total,prev, pager, next"
                :total="total"
                @current-change="currentChange"
                :page-size="9"
                :current-page="page"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
        </div>
        <!-- <div class="content-right">
          <Advertising></Advertising>
          <know-points class="margin" />
          <hot-recommend class="margin" />
        </div> -->
      </div>
    </div>
    <JoinPop ref="JoinPop"></JoinPop>
    <diy-dialog
      class="question-save-dialog"
      ref="questionSave"
      :width="'70vw'"
      :before-close="
        (c, t) => {
          return t === 'confirm'
            ? ($refs.questionSaveForm.submitForm(() => {
                page = 1;
                getTopicList(where);
                c && c();
              }),
              false)
            : true;
        }
      "
    >
      <div slot="title">新建题目</div>
      <question-save ref="questionSaveForm">
        <span slot="footer"></span>
      </question-save>
    </diy-dialog>
  </div>
</template>

<script>
import JoinPop from "../problemList/components/JoinPop.vue";
import ProblemListTable from "@/components/ProblemListTable/ProblemListTable.vue";
import Advertising from "@/components/Advertising/Advertising.vue";
import { topicList, remove } from "@/api/topic.js";
import SearchFilter from "@/components/com/SearchFilter";
// import QuestionSave from "@/views/api/teaching/question/save";
import QuestionSave from "@/views/topic/question/save";
import KnowPoints from "@/views/api/com/knowPoints";
import HotRecommend from "@/views/api/com/hotRecommend";
export default {
  components: {
    HotRecommend,
    KnowPoints,
    SearchFilter,
    JoinPop,
    ProblemListTable,
    Advertising,
    QuestionSave,
  },
  data() {
    return {
      where: {},
      typeCourse: [],
      questionData:[],
      currentRow: {},
      // operationTable: {
      //   tableData: [],
      //   total: 0,
      // },
      total:0,
      //分页相关
      page: 1,
      page_size: 50,
      options: {},
      loading: false,
    };
  },
  methods: {
    routeUpdate() {
      this.where = { ...this.$route.query, ...this.$route.query.params };
    },
    importData() {
      this.$refs.JoinPop.show = true;
    },
    rowDblclick(e) {
      // if (!localStorage.getItem("user_token")) {
      //   this.$store.state.lodinShow = true;
      //   return;
      // }
      if (this.userType == 0) {
        this.$store.state.showBinding = true;
        return;
      }
      if (this.isChoice) {
        this.$emit("rowSubject", e);
        return;
      }
      console.log(e,"eeeee")
      // return
      this.$handleRoute({ id: e.id, is_buy: e.is_buy }, 'QuestionInfo','_blank');
    },
    // 查看我的题目
    showMytopicList() {
      this.$handleRoute("/topic/myTopicList");
    },
    goNewTopicList() {
      // this.$handleRoute("teachingQuestionCreate");
      this.$handleRoute("topicQuestionCreate");
    },

    //获取题目列表
    getTopicList(param = {}) {
      this.loading = true;
      topicList({
        ...param,
        page: this.page,
        page_size: this.page_size,
      })
        .then(({ data }) => {
          console.log(data,"dataaaaaaa")
          this.total = data.total;
          this.questionData = data.data;
          // this.$store.commit("SET", { key: "headerValue.flag", value: false });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    currentChange(e) {
      this.page = e;
      this.getTopicList(this.where);
    },
    del(row) {
      this.$confirm("确定要删除题目吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          remove({ id: row.id }).then((res) => {
            this.$message({
              type: "success",
              message: "删除成功!",
            });
            this.currentChange(1);
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-card__body {
    position: relative;
    // display: flex;
    // justify-content: space-between;
    // // align-items: center;
    // .classify {
    //   flex: 1;
    // }
    .operate{
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
.btn {
    background: #2d93f8;
    border-radius: 2px;
    color: #fff;
    display: inline-block;
    padding: 0px 10px;
  }
.topic {
  .operation {
    font-size: 14px;
    text-align: center;
    .el-icon-delete {
      margin-left: 0 !important;
    }
  }
  .box {
    display: flex;
    justify-content: space-between;

    .content-left {
      // width: 70%;
      width: 100%;
      .el-icon-star-off,
      .el-icon-download,
      .el-icon-star-on,
      .el-icon-delete {
        cursor: pointer;
        font-size: 20px;
        color: #000;
        margin-left: 0.5rem;
      }
      .table-content {
        margin-top: 20px;
        padding: 1rem;
        background-color: #fff;
        .pagination {
          text-align: center;
          margin: 2rem 0;
        }
      }

      .operate {
        // margin-top: 40px;
        // margin-bottom: 0.5rem;
        display: flex;
        justify-content: flex-end;
        .new_problemList {
          margin-left: 1rem;
        }
        .myEstablish {
          background-color: #fff;
          border: 1px solid #00957e;
          color: #00957e;
          height: 24px;
          // box-sizing: border-box;
        }
      }
    }
    .content-right {
      width: 27%;

      .margin {
        margin-top: 40px;
      }
    }
  }
}
</style>
